<script setup lang="ts">
const props = defineProps({
  label: { type: String, required: true },
})
</script>

<template>
  <p v-if="props.label" class="text-beqom-red font-medium">
    {{ props.label }}
  </p>
</template>
